// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/nyimg/qxfw12.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "*[data-v-3385c3a2]{box-sizing:border-box}p[data-v-3385c3a2]{margin:0}.title[data-v-3385c3a2]{text-indent:2em;padding:.01333rem .01333rem .02667rem .01333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 130%;font-size:.016rem;line-height:.024rem;letter-spacing:.00267rem}.title p button[data-v-3385c3a2]{font-size:.01867rem;padding:.00667rem .04rem;border-radius:.06667rem;background:#fdd744;color:#1159aa;border:none}.msg[data-v-3385c3a2]{width:94%;color:#000;margin:0 auto;background:#fff;position:relative;top:-.02rem;border-radius:.01333rem;height:70%;overflow-y:auto}.msg ul[data-v-3385c3a2]{width:100%}.msg ul li[data-v-3385c3a2]{padding:.00667rem;border-bottom:.00133rem solid #d4d4d4}.msg ul li p[data-v-3385c3a2]{font-size:.02133rem}.msg ul li p[data-v-3385c3a2],.msg ul li span[data-v-3385c3a2]{padding:0 0 .00667rem .01333rem}.msg ul li span[data-v-3385c3a2]{color:#666;font-style:oblique;font-size:.01867rem}", ""]);
// Exports
module.exports = exports;
