
<template>
<div style="background-color: #FAFAFA;height:100%">
    <div class="title">
        {{ title }}

    </div>
    <div class="msg">
<ul>
    <li v-for="(item,index) in list" :key="index" @click="xiazaidoc(item)">
    <p>{{ item.Product_Name }}</p>
    <span>{{ item.add_time.replace('T',' ') }}</span>
    </li>
</ul>
    </div>
    <van-overlay z-index="10000" :show="overlayLoading" ><van-loading class="wrapper" type="spinner" /></van-overlay>
</div>
</template>

<script>
import request from "@/utils/request.js";
import { ImagePreview, Toast } from "vant";
export default {
  name: '',
  components: {},
  data () {
    return {
        overlayLoading:false,
        title:'降水会导致交通阻塞,影响居民正常出行,甚至发生交通事故。布设城市降水监测网络,准确指导降水影响交通过程、城市水循环过程、周边农业生产等。降水会导致交通阻塞,影响居民正常出行,甚至发生交通事故。降水会导致交通阻塞,影响居民正常出行,甚至发生交通事故。',
        list:[
            {name:'2023年4月5日邯郸市内涝服务专报.doc',time:'2023-04-15   15:32:00'},
            {name:'2023年4月5日邯郸市内涝服务专报.doc',time:'2023-04-15   15:32:00'},
            {name:'2023年4月5日邯郸市内涝服务专报.doc',time:'2023-04-15   15:32:00'},
            {name:'2023年4月5日邯郸市内涝服务专报.doc',time:'2023-04-15   15:32:00'},
            {name:'2023年4月5日邯郸市内涝服务专报.doc',time:'2023-04-15   15:32:00'},
        ]
    }
  },
  created () {
    console.log(this.$route.query.id);
    let id=this.$route.query.id
   // http://handanwx.qx121.net//api/Wechat/GetTypeAll?code=5
   request({
    url:'/api/Wechat/GetTypeAll',
      method: "post",
      params:{
       code:5
      }
   }).then(res=>{
    console.log(res);
    res.Result.forEach(item=>{
if (item.Id==id) {
    this.title=item.Type_Remark
    request({
        url:'/api/Wechat/GetQxbw',
      method: "post",
      params:{
       type:item.Type_Name
      }  
    }).then(re=>{
        console.log(re);
        this.list=re.data
    })
}
    })
   })
   },
  mounted () { },
  methods: { 
    xiazaidoc(item){

      let imgs = ['png','jpg'];
      let fileName = item.Product_accessory.split('.');
      if(imgs.includes(fileName[1])){
        this.showLgImg(radarUrl + item.Product_accessory)
      }else{
        // let url = 'http://api.qx121.net:8097/web/office/index.html?url=' +  radarUrl + item.Product_accessory
        let url = requestUrl + 'Product/office/index.html?url=' +  radarUrl + item.Product_accessory
        window.location.href =url
      }
    },
    showLgImg(url) {
      ImagePreview({
        images: [url],
        closeable: true,
      });
    },
  },
  computed: {}
}
</script>
<style scoped lang='scss'>
*{
    box-sizing: border-box;
}
p{
    margin:0
}
.title{
    text-indent:2em;
    padding:10px 10px 20px 10px;
    background: url('~@/assets/nyimg/qxfw12.jpg');
    background-size:100%  130%;
    font-size:12px;
    line-height: 18px;
    letter-spacing:2px;
    p{
        button{
            font-size:14px;
            padding:5px 30px;
            border-radius:50px ;
            background: #FDD744;
            color: #1159AA;
            border:none
        }
    }
}
.msg{
    width:94%;
color:#000;
margin:0 auto;
background: #fff;
position: relative;
top:-15px;
border-radius: 10px;
height:70%;
overflow-y: auto;
ul{
    width: 100%;
   
    li{
        padding:5px;
        border-bottom:1px solid #d4d4d4;
p{
    padding:0 0 5px 10px;
    font-size: 16px;
}
span{
    padding:0 0 5px 10px;
    color:#666;
    font-style:oblique;
    font-size: 14px;
}
    }
}
}
</style>
